// @cms-next @tix/api-sdk

export interface MatcherConfig {
  statusCode: number
  tester?: String | RegExp | Function
}

export class Matcher {
  constructor(private cfg: MatcherConfig | number) {
    if (typeof this.cfg === 'number') {
      this.cfg = {
        statusCode: cfg
      }
    }
  }

  match(error: Object): boolean {
    if (this.cfg.statusCode !== undefined && error.status !== this.cfg.statusCode) {
      return false
    }

    if (!this.cfg.tester) {
      return true
    }

    this.message = this.constructor.getErrorMessage(error)

    const eData = this.constructor.#getErrorData(error)
    this.code = eData._data && eData._data.length ? eData._data[0]._code : ''

    if (typeof this.cfg.tester === 'function') {
      return Boolean(this.cfg.tester(error, this.code))
    }

    if (typeof this.cfg.tester === 'string') {
      return this.message.includes(this.cfg.tester) || this.code.includes(this.cfg.tester)
    }

    // RegExp
    return this.cfg.tester.test(this.message) || this.cfg.tester.test(this.code)
  }

  static #getErrorData(error): Object {
    return error.data || error.body || {}
  }

  static getErrorMessage(error): string {
    const eData = this.#getErrorData(error)
    return eData.message ? eData.message : eData._data && eData._data.length ? eData._data[0]._description : ''
  }
}
