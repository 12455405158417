// @cms-next @tix/api-sdk

import toast from '@/classes/ToastSingleton'
import {MatcherConfig, Matcher} from './Matcher'
import {NonAlarmingError, NotAuthorisedAPIError, NotFoundError} from './Misc'

interface HandlerConfig {
  matcher: MatcherConfig | number | boolean // TRUE means match all errors (used in the default handler)
  // // Displays generic flash message
  // alertUser?: boolean | Function
  // TRUE: Throws an error so that the promise rejects. If there is a matcher, the error is NonAlarmingError so that it does not end up in TrackJS, otherwise it is the original error.
  // FALSE: Does not reject, but returns the "return" value (or the error status code if no "return" defined)
  reject?: boolean
  // Performs a task when the match occurs.
  // This happens after alerting the user (where alertUser === TRUE), but before rejecting the promise (where reject === TRUE).
  task?: Function
  // Factory for the error to be thrown where reject === TRUE (note that setting errorMaker will act as if reject = TRUE, even if not set so)
  errorMaker?: Function
  // Value to return where "reject" is FALSE (the error status code is returned by default)
  return?: any
}

let defaultHandler = null

export default class Handler {
  #matcher: Matcher

  constructor(private cfg: HandlerConfig | number | boolean) {
    if (typeof this.cfg === 'number') {
      this.cfg = {
        matcher: this.cfg
      }
    }
    if (this.cfg.matcher !== true) {
      this.#matcher = new Matcher(this.cfg.matcher)
    }
  }

  apply(error: Error): any {
    if (this.#matcher && !this.#matcher.match(error)) {
      return false
    }
    const message = (this.#matcher && this.#matcher.message) || Matcher.getErrorMessage(error)

    const alertUser = typeof this.cfg.alertUser === 'function' ? this.cfg.alertUser(error) : Boolean(this.cfg.alertUser)

    if (alertUser) {
      toast.danger(message)
    }

    if (this.cfg.task) {
      this.cfg.task(error)
    }
    if (this.cfg.reject || this.cfg.errorMaker) {
      throw this.cfg.errorMaker ? this.cfg.errorMaker(error, message) : new NonAlarmingError(message)
    }
    return this.cfg.return !== undefined ? (typeof this.cfg.return === 'function' ? this.cfg.return(error, this.#matcher.code, message) : this.cfg.return) : error.status
  }

  static getDefault(): Handler {
    if (defaultHandler === null) {
      // By default, alert the user and throw the error as is so that it ends up in TrackJS
      defaultHandler = new Handler({
        matcher: true,
        alertUser: err => err.status === 422,
        reject: true,
        errorMaker(err, message) {
          if (err.status === 404) {
            return new NotFoundError()
          }
          if (err.status === 422) {
            return new NonAlarmingError(message)
          }
          if ([401, 403].includes(err.status)) {
            const isAuthenticated = window.theApp.isAuthenticated
            if (!isAuthenticated) {
              // We're not authenticated, yet the API call was made somehow. How come?
              // Make sure we're on the Log In screen:
              window.theApp.onLogOut()
            }
            // An API call has been made which should not have been made.
            // Let it be handled as per https://tixtrack.atlassian.net/browse/TIC-2302
            return new NotAuthorisedAPIError(`Unexpected API error ${err.status}; Was authenticated: ${isAuthenticated ? 'TRUE' : 'FALSE'}`)
          }
          return message
        }
      })
    }
    return defaultHandler
  }
}
