import Handler from '@/api/error/Handler'

// Certain API endpoints need not "_seller" param supplied -
// - even if we have "seller" in the CMS page URL
const noSellerEndpoints = {}
;['config', 'my/seller', 'my/identity', 'my/identity_link'].forEach(endpoint => {
  noSellerEndpoints[endpoint] = true
})

function callApi(app) {
  const apiUrl = '/api'

  function adjustCounter(up, ui) {
    if (ui === false || window.suppressGlobalSpinner) {
      return
    }
    const ch = ui || window
    if (!ch.currentApiCallCounter) {
      ch.currentApiCallCounter = 0
    }
    if (up) {
      ch.currentApiCallCounter += 1
    } else {
      ch.currentApiCallCounter -= 1
    }
    if ([0, 1].includes(ch.currentApiCallCounter)) {
      window.treatApiPendingUI(ch.currentApiCallCounter === 1, ui)
    }
  }

  function resetSpinners(ui) {
    delete window.suppressGlobalSpinner
    delete window.currentApiCallCounter
    if (ui) {
      delete ui.currentApiCallCounter
    }
  }

  function mergeMeta(response) {
    if (!response.meta || !response.meta._data) {
      return response
    }

    const resourcesEmptyMetaAddedTo = []
    let resource

    // loop over each metadata item
    response.meta._data.forEach(meta => {
      // add empty _meta objects to all of a resource
      if (resourcesEmptyMetaAddedTo.indexOf(meta.resource) === -1) {
        resourcesEmptyMetaAddedTo.push(meta.resource)
        response[meta.resource]._data.forEach(res => {
          if (!res._meta) {
            res._meta = {}
          }
        })
      }
      // find the resource matching the metadata item
      resource = response[meta.resource]._data.find(candidateResource => candidateResource.id === meta.resource_id)
      // assign the meta data
      if (resource) {
        resource._meta[meta.metakey] = meta.value
      }
    })
    return response
  }

  function createReconnectPromise() {
    return new Promise((resolve, reject) => {
      function poll() {
        if (Offline.state === 'up') {
          resolve()
          return
        }
        setTimeout(poll, 100)
      }
      poll()
      setTimeout(reject, 30000)
    })
  }

  function addSellerScope(endpoint) {
    if (!noSellerEndpoints[endpoint.split('?')[0]] && window.theApp.seller) {
      // Dynamically scope API session to seller - instead of patching it.
      // The "_seller" param must go to the URL not to the JSON body
      // so add it to the endpoint explicitly and not to the args.
      const sellerParam = `_seller=${window.theApp.seller.id}`
      const separator = endpoint.indexOf('?') === -1 ? '?' : '&'
      endpoint += separator + sellerParam
    }
    return endpoint
  }

  function createPromise(method, url, data, ops) {
    const ui = ops ? ops.ui : undefined
    adjustCounter(true, ui)
    return createReconnectPromise()
      .then(() => app.$http[method](url, data))
      .then(response => {
        adjustCounter(false, ui)
        if (window.JSHASH) {
          const lastGitHash = response.headers.get('githash')
          if (lastGitHash && window.JSHASH !== lastGitHash) {
            // The CMS has been updated. Schedule page reload
            window.pageReloadScheduled = true
          }
        }
        return response.data ? (!ops || ops.noMetaMerge !== true ? mergeMeta(response.data) : response.data) : response
      })
      .catch(err => {
        adjustCounter(false, ui)
        let handled = false
        // See if the promise caller has specified how to handle this error
        let handlers = []
        if (ops && ops.handler !== undefined) {
          handlers.push(ops.handler)
          delete ops.handler
        }
        if (ops && Array.isArray(ops.handlers)) {
          handlers = handlers.concat(ops.handlers)
          delete ops.handlers
        }
        let i = 0
        // Loop through the handlers, break when one applies.
        // Note that a new error may be thrown in here.
        for (; i < handlers.length; i++) {
          if (!(handlers[i] instanceof Handler)) {
            handlers[i] = new Handler(handlers[i])
          }
          handled = handlers[i].apply(err)
          if (handled !== false) {
            break
          }
        }
        return handled === false ? Handler.getDefault().apply(err) : handled
      })
  }

  function createAPIPromise(method, endpoint, params, ops) {
    return Promise.resolve().then(() => {
      endpoint = ops && ops.noseller ? endpoint : addSellerScope(endpoint)
      method = method.toLowerCase()
      if (method === 'get') {
        params = {params}
      }
      return createPromise(method, `${apiUrl}/${endpoint}`, params, ops)
    })
  }

  return {
    get(endpoint, args, options) {
      return createAPIPromise('GET', endpoint, args, options)
    },
    post(endpoint, args, options) {
      return createAPIPromise('POST', endpoint, args, options)
    },
    put(endpoint, args, options) {
      return createAPIPromise('PUT', endpoint, args, options)
    },
    patch(endpoint, args, options) {
      return createAPIPromise('PATCH', endpoint, args, options)
    },
    delete(endpoint, args, options) {
      return createAPIPromise('DELETE', endpoint, args, options)
    },
    addSellerScope(endpoint) {
      return addSellerScope(endpoint)
    },
    download(apiUri, params, onSuccess, onFailure, ops) {
      const ui = on => {
        if (ops && ops.ui) {
          window.treatApiPendingUI(on, ops.ui)
        }
      }
      // "onFailure" can be:
      // 1) null; 2) function; 3) context object to call "$dispatch('apiError'" on
      if (typeof onFailure !== 'function') {
        const context = onFailure
        onFailure = message => {
          try {
            message = JSON.parse(message.replace(/^[^{]*/i, '').replace(/[^}]*$/i, ''))._data[0]._description
          } catch (e) {
            message = 'Failed to download CSV'
          }
          if (context) {
            context.$dispatch('apiError', {message})
          }
        }
      }
      ui(true)
      params = $.param(params)
      params = window.isEmpty(params) ? '' : `&${params}`
      fetch(`/api/${addSellerScope(apiUri)}${params}`)
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = `${apiUri}.csv`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
          ui(false)
          if (onSuccess) {
            onSuccess()
          }
        })
        .catch(() => {
          ui(false)
          if (onFailure) {
            onFailure()
          }
        })
    },
    meta(resource, metakey, value, ops) {
      resource = `${resource}/meta`
      if (value === null) {
        value = ''
      }
      if (value !== false) {
        return createAPIPromise(
          'POST',
          resource,
          {
            metakey,
            value: String(value)
          },
          ops
        )
      }
      ops = ops || {}
      ops.handler = 404 // Allow for metas to not exist when trying to delete them
      return createAPIPromise('DELETE', `${resource}/${encodeURIComponent(metakey)}`, null, ops)
    },
    promise(...args) {
      return createPromise(...args)
    }
  }
}

export default callApi
