// https://tixtrack.atlassian.net/browse/TIC-2087
class Pendo {
  static #setupDone = false
  static #initDone = false
  static #lastAnonState = null

  static #ensureSetup() {
    if (!this.#setupDone) {
      ;(function (apiKey) {
        ;(function (p, e, n, d, o) {
          var v, w, x, y, z
          o = p[d] = p[d] || {}
          o._q = o._q || []
          v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']
          for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
              o[m] =
                o[m] ||
                function () {
                  o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)))
                }
            })(v[w])
          y = e.createElement(n)
          y.async = !0
          y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js'
          z = e.getElementsByTagName(n)[0]
          z.parentNode.insertBefore(y, z)
        })(window, document, 'script', 'pendo')
      })(CONFIG.pendo.config.api_key)
      this.#setupDone = true
    }
  }

  static #isEnabled() {
    const identity = theApp.identity
    if (!identity) {
      return false
    }
    const config = CONFIG?.pendo?.config
    if (!config || !config.enable || location.hostname.endsWith('.local')) {
      return false
    }
    if (config.always_include.includes(identity.email)) {
      return true
    }
    if (config.exclude.some(ex => identity.email.includes(ex))) {
      return false
    }
    return true
  }

  static onIdentityChange() {
    const identity = theApp.identity
    const isAnon = !identity
    if (
      this.#isEnabled() || // Start tracking on login unless disabled
      (this.#setupDone && isAnon) // Anonymise on logout if already tracking.
    ) {
      this.#ensureSetup()
      if (this.#lastAnonState !== isAnon) {
        const m = this.#initDone ? 'identify' : 'initialize'
        const visitor = {
          isProduction: TIXAPI_BASE_URL.includes('api.ticketure')
        }
        if (identity) {
          visitor.id = identity.id
        }
        pendo[m]({
          visitor,
          account: {
            id: PORTAL.slug
          }
        })
        this.#lastAnonState = isAnon
        if (!this.#initDone) {
          this.#initDone = true
        }
      }
    }
  }
}

export default Pendo
